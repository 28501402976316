import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'join'
})
export class JoinPipe implements PipeTransform
{
    public transform(values: any[], separator: string = ', ', defaultValue: string = ''): string
    {
        return values && values.length > 0 ? values.map(item => item.value).join(separator) : defaultValue;
    }
}
