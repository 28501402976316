import {inject, Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {environment} from "../../../environments/environment";
import {ToastService} from "./toast.service";
import Swal from "sweetalert2";
import {Router} from "@angular/router";
import {HttpErrorResponse, HttpStatusCode} from "@angular/common/http";
import { FormGroup } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export abstract class BaseService {
    public apiUrl: string;
    public router: Router = inject(Router);

    protected constructor(
        public toastService: ToastService
    ) {
        this.apiUrl = environment.apiUrl;
    }

    // TODO: Convert to strict type after everything is using ApiHttpErrorResponse
    public handleError(response: any, form?: FormGroup): void
    {
        if (form) {
            form.enable();
        }

        if (response.status === HttpStatusCode.UnprocessableEntity && form) {
            this.handleValidationError(response, form);
        } else {

            console.log('error response',response);
            switch (response?.error?.type) {
                case 'DuplicateVehicleException':
                    Swal.fire({
                        icon: 'warning',
                        title: response?.error?.title ?? 'An error has occurred.',
                        html: `${response?.error?.message}.`,
                        showCancelButton: true,
                        cancelButtonText: 'Cancel',
                        confirmButtonText: 'Go to Vehicle',
                        reverseButtons: true,
                        focusConfirm: false,
                    }).then((result: any) => {
                        if (result.isConfirmed && response?.error?.meta?.model?.id) {
                            this.router.navigateByUrl(`/app/vehicles/${response?.error?.meta?.model?.id}/update`);
                        }
                    });
                    return;

                case 'HttpNotFoundException':
                    Swal.fire({
                        icon: 'warning',
                        title: response?.error?.title ?? 'An error has occurred.',
                        html: `${response?.error?.message}<br /><br /> If this issue persists, manually enter the vehicle details.`,
                        showCancelButton: true,
                        cancelButtonText: 'Cancel',
                        confirmButtonText: 'Ok',
                        reverseButtons: true,
                        focusConfirm: false,
                    });
                    return;

                    //NOTE: If the issue persis
                case 'CanNotCreateOrFindAccountException':
                case 'CannotCancelDepositException':
                case 'CouldNotCreateAccountException':
                    
                    Swal.fire({
                        icon: 'error',
                        title: response?.error?.title ?? 'An error has occurred.',
                        html: `${response?.error?.message}<br /><br /> If this issue persists get in touch with <a href="tel:01482208774">support.</a>`,
                        showCancelButton: false,
                        confirmButtonText: 'Ok',
                        reverseButtons: true,
                        focusConfirm: false,
                    });
                    return;

                    //NOTE: Directly get in touch
                case 'CannotCreateVehicleException':
                case 'CannotCreateInvoiceException':
                case 'CannotUpdateVehicleException':
                case 'CannotUpdateQuoteException':
                case 'CannotCreateInvoiceInXeroException':
                    Swal.fire({
                        icon: 'error',
                        title: response?.error?.title ?? 'An error has occurred.',
                        html: `${response?.error?.message}<br /><br /> Please get in touch with <a href="tel:01482208774">support.</a>`,
                        showCancelButton: false,
                        confirmButtonText: 'Ok',
                        reverseButtons: true,
                        focusConfirm: false,
                    });
                    return;
            }
        }

        this.toastService.error(response?.error?.message, response?.error?.title);
    }

    public handleValidationError(response: HttpErrorResponse, form: FormGroup)
    {
        form.markAsUntouched();
        form.markAsPristine();

        for (let e in response.error.errors) {
            let control = form.get(e);

            if (control) {
                control.markAsTouched();
                control.markAsDirty();
                control.setErrors({error: response.error.errors[e]});
            } else {
                return undefined;
            }
        }
    }
}
